import React, { Component } from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom'
// var front = '<'
// var back = '/>'

class Footer extends Component {

    
    render () {
 
        return (
            <div className='footer-container'>
                <footer>
                    <a href='www.myfurbook.com'/>

                </footer>
            </div>

        )
    }

    

}

export default Footer;

